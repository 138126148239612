"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lib_1 = require("./lib");
const lib_2 = require("./lib");
const lib_3 = require("./lib");
const lib_4 = require("./lib");
const lib_5 = require("./lib");
const lib_6 = require("./lib");
const lib_7 = require("./lib");
const lib_8 = require("./lib");
const lib_9 = require("./lib");
const lib_10 = require("./lib");
const lib_11 = require("./lib");
const lib_12 = require("./lib");
const lib_13 = require("./lib");
const lib_14 = require("./lib");
require("../scss/style.scss");
document.addEventListener('DOMContentLoaded', lib_1.setSvh);
window.addEventListener('resize', lib_1.setSvh);
document.addEventListener('DOMContentLoaded', lib_9.setFooterHeightCP);
window.addEventListener('resize', lib_9.setFooterHeightCP);
document.addEventListener('DOMContentLoaded', lib_12.setCurrentSection);
document.addEventListener('DOMContentLoaded', lib_13.changeCharacterFace);
document.addEventListener('DOMContentLoaded', lib_2.setStaticSvh);
document.addEventListener('DOMContentLoaded', lib_10.humbergerMenuUtils);
document.addEventListener('DOMContentLoaded', lib_11.smoothScroll);
document.addEventListener('DOMContentLoaded', lib_3.ISOvserve);
document.addEventListener('DOMContentLoaded', lib_4.ISOvserveRepeat);
document.addEventListener('DOMContentLoaded', lib_5.disableFvProps);
document.addEventListener('DOMContentLoaded', lib_14.scrollHint);
document.addEventListener("DOMContentLoaded", lib_6.initParallax);
document.addEventListener("DOMContentLoaded", lib_7.initPaperAnimation);
document.addEventListener('DOMContentLoaded', lib_8.initMousemoveAnimation);
